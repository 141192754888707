import { uniq } from "lodash";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { AssessmentScopeIntegration } from "shared/types/assessment";
import {
  AnyNode,
  AssessmentNodes,
  NodeFor,
  TargetNodeTypes,
} from "shared/types/assessment/data";

import {
  GraphVisualization,
  NodeFlowRenderer,
  NodeFlowSpec,
} from "./GraphVisualization";
import { NodeTitler } from "./node/NodeText";

const linkableTypes: readonly string[] = ["authentication", ...TargetNodeTypes];
const linkItem = (current: AnyNode) => {
  const { type, key } = current;

  return `${type}/${encodeURIComponent(key)}`;
};

/** Visualizes a graph fragment for a node
 *
 * Renders only the local graph composed of the node and its nearest neighbors.
 *
 * The user can navigate to update the visualization to a neighbor by clicking on
 * the neighbor title link, in the case that neighbor is renderable (viz., if its
 * `type` appears in `TargetNodeTypes`).
 */
export const NodeLocaleVisualization = (props: {
  current: AnyNode;
  integration: AssessmentScopeIntegration;
  renderer: NodeFlowRenderer<AssessmentNodes>;
}) => {
  const { search } = useLocation();
  const { current, integration, renderer } = props;

  const titler = useMemo(() => NodeTitler(integration), [integration]);
  const link = useMemo(() => {
    return (target: NodeFor<keyof AssessmentNodes>) => {
      if (!linkableTypes.includes(target.type)) return undefined;
      const linkSearch = new URLSearchParams(search);
      linkSearch.set("show", target.type);
      const isMonitorPath = location.pathname.split("/")[5] === "monitors";
      // If this is a monitor, then navigation should direct to an explore page, as only
      // this current node can be rendered on this monitor finding page
      return `${isMonitorPath ? "../../../../explore" : "../.."}/${linkItem(
        current
      )}?${linkSearch.toString()}`;
    };
  }, [current, search]);

  const nodes = useMemo(() => {
    const nParents = current.parents.length;
    const nChildren = current.children.length;
    const uniqueParentTypes = uniq(current.parents.map((p) => p.type));
    const uniqueChildTypes = uniq(current.children.map((c) => c.type));
    return [
      {
        inner: current,
        display: !current.parents.length
          ? "root"
          : !current.children.length
          ? "leaf"
          : "inner",
        position: { d: 0, x: 0, y: 0 },
      },
      ...current.parents.map((p, ix) => {
        const typeIndex = uniqueParentTypes.indexOf(p.type);
        return {
          inner: p,
          display: "root",
          position: { d: typeIndex, x: -1, y: ix - nParents / 2 },
        };
      }),
      ...current.children.map((c, ix) => {
        const typeIndex = uniqueChildTypes.indexOf(c.type);
        return {
          inner: c,
          display: "leaf",
          position: { d: typeIndex, x: 1, y: ix - nChildren / 2 },
        };
      }),
    ] as NodeFlowSpec<AssessmentNodes>[];
  }, [current]);

  const edges = useMemo(
    () => [
      ...current.parents.map((p) => ({
        parent: p,
        child: current,
      })),
      ...current.children.map((c) => ({
        parent: current,
        child: c,
      })),
    ],
    [current]
  );

  return (
    <GraphVisualization<AssessmentNodes>
      edges={edges}
      link={link}
      nodes={nodes}
      renderer={renderer}
      titler={titler}
    />
  );
};
