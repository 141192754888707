import { CatalogContext } from "components/Catalog/context";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import { VerticalSpacedDiv } from "components/divs";
import { capitalize } from "lodash";
import { ReactNode, useContext } from "react";
import { grantNodeToPermissionSet } from "shared/assessment/render";
import { Node } from "shared/graph/types";
import {
  AssessmentScopeBase,
  AssessmentScopeIntegration,
} from "shared/types/assessment";
import {
  AssessmentNodes,
  GrantNode,
  NodeFor,
  PrincipalNode,
} from "shared/types/assessment/data";

import { PermissionLink } from "../../components/cells/PermissionLink";
import { CredentialDisplay } from "../cells/Credential";
import {
  RiskGroupedPermissionList,
  toPermissionAggregate,
} from "../cells/PermissionAggregate";
import { PrincipalCell } from "../cells/Principal";
import { Resource } from "../cells/Resource";
import { RiskLink } from "../cells/RiskLink";

const HydratedRiskLink: React.FC<{ id: string }> = ({ id }) => {
  const { risks } = useContext(CatalogContext);
  return id in risks ? <RiskLink risk={risks[id]} /> : <>{id}</>;
};

export const NodeTitler =
  (integration: AssessmentScopeIntegration) =>
  (node: Node<AssessmentNodes, keyof AssessmentNodes>) =>
    capitalize(NodeLabel(node, integration));

export const NodeLabel = (
  node: Node<AssessmentNodes, keyof AssessmentNodes>,
  integration: AssessmentScopeIntegration
) =>
  node.type === "grant"
    ? "grant"
    : node.type === "permissionSet" && integration === "gcloud"
    ? "role"
    : node.type === "usage"
    ? "usage"
    : node.type;

export const NodeText = (
  current: NodeFor<keyof AssessmentNodes> | undefined,
  integration: AssessmentScopeBase["integration"],
  options?: { detailed?: boolean; hideThis?: boolean }
): { [K in keyof AssessmentNodes]?: (node: NodeFor<K>) => ReactNode } => ({
  credential: (n) => <CredentialDisplay credential={n.data} id={n.key} />,
  grant: (n) => {
    const main =
      !options?.hideThis && current?.type === "grant" && current?.key === n.key
        ? "This grant"
        : grantNodeToPermissionSet(n);

    const content = options?.detailed ? (
      <VerticalSpacedDiv style={{ gap: "0em" }}>
        <div>{main} to</div>
        <div>{n.data.principal}</div>
      </VerticalSpacedDiv>
    ) : (
      main
    );
    return <GraphTooltip title={content}>{content}</GraphTooltip>;
  },
  usage: (n) => (
    <GraphTooltip
      width="500px"
      title={
        <>
          <RiskGroupedPermissionList
            permissions={
              (current as GrantNode | PrincipalNode)?.aggregates?.permissions ??
              toPermissionAggregate(n)
            }
            initialSelected={n.data.type}
            integration={integration}
          />
        </>
      }
    >
      {n.data.type === "unknown" ? "Potentially used" : capitalize(n.data.type)}{" "}
      permissions ({n.children.length})
    </GraphTooltip>
  ),
  permission: (n) => (
    <PermissionLink key={n.key} permission={n.key} integration={integration} />
  ),
  principal: (n) => <PrincipalCell principalData={n.data} />,
  resource: (n) => <Resource resource={n.key} shortPath />,
  risk: (n) => <HydratedRiskLink key={n.key} id={n.key} />,
});
