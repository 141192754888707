import { ClipDiv } from "components/divs";
import moment from "moment";
import { CredentialNode } from "shared/types/assessment/data/credential";

import { ExportColumnType } from "../../AssessmentExport";
import { CredentialDisplay } from "../../cells/Credential";
import { LastAuthenticated } from "../../cells/LastAuthenticated";
import { LastRotated } from "../../cells/LastRotated";
import { PrincipalCell, principalCellValue } from "../../cells/Principal";
import { RiskAggregate, riskSorter } from "../../cells/RiskAggregate";
import { genericExport } from "../../export";
import { numberSorter, stringSorter } from "../../sort";
import { AssessmentColumnProps } from "../columns";

const replaceNaN = (value: number, fallback: number) =>
  isNaN(value) ? fallback : value;

export const credentialColumns = (
  props: AssessmentColumnProps
): ExportColumnType<CredentialNode>[] => [
  {
    key: "credential",
    title: "Credential",
    sorter: (left, right) => stringSorter(left.key, right.key),
    render: (_, node) =>
      node.key && (
        <ClipDiv>
          <CredentialDisplay credential={node.data} id={node.key} {...props} />
        </ClipDiv>
      ),
  },
  {
    key: "principal",
    title: "Principal",
    sorter: (left, right) =>
      stringSorter(
        left.aggregates.principal[0].id,
        right.aggregates.principal[0].id
      ),
    render: (_, node) => (
      <ClipDiv>
        <PrincipalCell
          principalData={node.aggregates.principal[0]}
          {...props}
        />
      </ClipDiv>
    ),
    export: genericExport((node) =>
      principalCellValue(node.aggregates.principal[0], props.integration)
    ),
  },
  {
    key: "lastAuthnTime",
    title: "Last Used",
    sorter: (left, right) =>
      // Ordering is reversed because it looks back in time
      numberSorter(
        replaceNaN(right.data.lastAuthnTime, 0),
        replaceNaN(left.data.lastAuthnTime, 0)
      ),
    render: (_, node) => (
      <ClipDiv>
        <LastAuthenticated
          principal={node.aggregates.principal[0]}
          last={node.data.lastAuthnTime}
        />
      </ClipDiv>
    ),
    export: genericExport((node) => node.data.lastAuthnTime),
  },
  {
    key: "lastRotatedTime",
    title: "Last Rotated",
    sorter: (left, right) =>
      // Cf. lastAuthnTime sorting
      numberSorter(
        replaceNaN(right.data.createdTime ?? 0, 0),
        replaceNaN(left.data.createdTime ?? 0, 0)
      ),
    render: (_, node) => <LastRotated credential={node.data} />,
    export: genericExport((node) =>
      node.data.createdTime ? moment(node.data.createdTime).format() : "unknown"
    ),
  },
  {
    key: "risk",
    title: "Risks",
    sorter: (left, right) =>
      riskSorter(left.aggregates.risks, right.aggregates.risks),
    render: (_, node) => <RiskAggregate risks={node.aggregates.risks} />,
    export: genericExport((node) => node.aggregates.risks),
  },
];
