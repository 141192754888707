import { groupBy } from "lodash";

import { max } from "../../graph/aggregate";
import { Reducers } from "../../graph/types";
import { DAYS } from "../../time";
import { AssessmentNodes } from "../../types/assessment/data";
import { PrincipalAggregates } from "../../types/assessment/data/principal";
import { STALE_CREDENTIAL_MILLIS } from "../issues/presets";
import { grantAggregate, keyAggregate, permissionAggregate } from "./shared";

export const credentialsByUsage = (
  credentials: PrincipalAggregates["credentials"]
) => {
  const threshold = Date.now() - 90 * DAYS;
  return groupBy(credentials, (c) =>
    c.lastAuthnTime >= threshold ? ("used" as const) : ("unused" as const)
  );
};
export const credentialsByStaleness = (
  credentials: PrincipalAggregates["credentials"]
) => {
  const threshold = Date.now() - STALE_CREDENTIAL_MILLIS;
  return groupBy(credentials, (c) =>
    c.createdTime && c.createdTime >= threshold
      ? ("fresh" as const)
      : ("stale" as const)
  );
};

export const principalReducers: Reducers<AssessmentNodes, PrincipalAggregates> =
  {
    credentials: keyAggregate("credential"),
    lastAuthEvent: max("credential", (n) => n.lastAuthnTime),
    grants: grantAggregate,
    permissions: permissionAggregate,
    risks: keyAggregate("risk"),
  };
